import React from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { convertMFGTS, convertAccessTS } from "./api/api";
import { successToaster, errorToaster } from "./helpers/toaster";
function ShowTreatmentPlan(props) {
  const { setStateObject, stateObject } = props;
  const updateTreatment = async (data) => {
    setStateObject((prevState) => ({
      ...prevState,
      updateTs: true,
    }));
    data.isEonMfg ? convertMFGTS(data.ownerType, data.treatmentSetupsUuid, stateObject.url) : 
    convertAccessTS(data.caseId, data.treatmentSetupsUuid, stateObject.url)
    successToaster("TS Approved successfully.")
    setStateObject((prevState) => ({     
      ...prevState,
      updateTs: false,
    }))
 
  };
  console.log(stateObject.isEonMFG, stateObject?.chosenTs?.id);
  const toggleTSModal = () => {
    setStateObject((prevState) => ({
      ...prevState,
      showConfirmation: !prevState.showConfirmation,
    }));
  };
  const ToggleEmpty = () => {};
  return (
    <Modal
      className="modalts"
      backdrop={false}
      isOpen={stateObject.showTS}
      toggle={!stateObject.updateTs ? toggleTSModal : ToggleEmpty}
    >
      <ModalHeader
        className="w-100 align-items-center"
        toggle={!stateObject.updateTs ? toggleTSModal : ToggleEmpty}
      >
        <Row className="w-100 align-items-center">
          <Col>TS Viewer</Col>
          <Col className="text-end">
            <Button
              size="sm"
              className={`h-100 float-right active-btn`}
              onClick={async () => {
                await updateTreatment(stateObject);
                setStateObject((prevState) => ({
                  ...prevState,
                  caseId: "",
                  uploading: false,
                  converting: false,
                  showError: false,
                  errors: [],
                  caseUuid: "",
                  treatmentSetups: [],
                  treatmentSetupsUuid: "",
                  chosenTs: {},
                  doctor: {},
                  patient: {},
                  ownerType: false,
                  isEonMFG: false,
                  showTS: false,
                  key: {},
                  url: {},
                  showConfirmation: false,
                  chosenFile: [],
                }));
              }}
            >
              Approve and Update TS
            </Button>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <iframe
          src={`${process.env.REACT_APP_TP_VIEWER}/?isM=true&treatmentSetupsId=${stateObject?.chosenTs?.id}`}
          width={"100%"}
          height={"100%"}
          className="viewer-iframe"
          allowFullScreen
        />
      </ModalBody>
    </Modal>
  );
}

export default ShowTreatmentPlan;
