import { useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Button } from "reactstrap";
import { errorToaster, successToaster } from "./helpers/toaster";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { SQSClient, SendMessageCommand } from "@aws-sdk/client-sqs";
import { DynamoDBClient, GetItemCommand } from "@aws-sdk/client-dynamodb";
import JSZip from 'jszip';
import X2JS from 'x2js';

const credentials = fromCognitoIdentityPool({
  clientConfig: { region: process.env.REACT_APP_AWS_REGION },
  identityPoolId: process.env.REACT_APP_AWS_IDENTITYPOOLID,
});

const s3 = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials,
});

const sqs = new SQSClient({
  region: process.env.REACT_APP_AWS_REGION,
  credentials,
});

const dynamo = new DynamoDBClient({
  region: process.env.REACT_APP_AWS_REGION,
  credentials,
});

const Upload = (props) => {
  const { stateObject, setStateObject } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [stepsNotEqual, setStepsNotEqual] = useState(true);
  let intervalId = useRef(null);
  const metaData = {
    caseId: stateObject?.caseId?.toString(),
    treatmentVersion: stateObject?.chosenTs?.id?.toString(),
  };
  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setErrorMessage("Invalid file format. Please upload a ZIP file.");
    } else {
      setErrorMessage("");
      setStateObject((prevState) => ({
        ...prevState,
        chosenFile: acceptedFiles[0],
      }));
      //extractXmlFromZip(acceptedFiles[0]);
    }
  };

  const handleUpload = async () => {
    const uniqueUuid = uuidv4();
    const putObjectCommand = new PutObjectCommand({
      Bucket: process.env.REACT_APP_AWS_S3,
      Key:`${process.env.REACT_APP_ENV}/raw/${stateObject.chosenFile.name}`,
      ContentType: stateObject.chosenFile.type,
      Body: stateObject.chosenFile,
      Metadata: metaData,
    });
    const sendMessageCommand = new SendMessageCommand({
      QueueUrl: process.env.REACT_APP_AWS_SQS_RAW,
      MessageBody: JSON.stringify({
        id: uniqueUuid,
        key: `${process.env.REACT_APP_ENV}/raw/${stateObject.chosenFile.name}`,
        case_id: metaData.caseId,
        ts_id: metaData.treatmentVersion,
      }),
    });

    const getItemCommand = new GetItemCommand({
      TableName: "glb-converter-service-finished-jobs",
      Key: {
        id: { S: uniqueUuid },
      },
      // AttributesToGet: ["someKey"],
    });

    setStateObject((prevState) => ({
      ...prevState,
      uploading: true,
    }));
    await s3.send(putObjectCommand).then(() => {
      setStateObject((prevState) => ({
        ...prevState,
        uploading: false,
        converting: true,
      }));
    });
    await sqs.send(sendMessageCommand);
    intervalId.current = setInterval(async () => {
      console.log("dynmoooooo");
      try {
        const { Item } = await dynamo.send(getItemCommand);
        if (!Item) return;
        console.log("******", Item.success);
        if (!Item.success.BOOL) throw new Error(Item.info.S);
        const url = stateObject.isEonMFG ? `https://${process.env.REACT_APP_AWS_S3_PROCESSED}.amazonaws.com/${decodeURI(
          Item.key.S
        )}` : decodeURI(
          Item.key.S
        )
        console.log("+++", { Item });
        clearInterval(intervalId.current);
        setStateObject((prevState) => ({
          ...prevState,
          showTS: true,
          converting: false,
          key: Item.key.S,
          url: `https://${process.env.REACT_APP_AWS_S3_PROCESSED}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${decodeURI(
            Item.key.S
          )}`,
        }));
        successToaster("Converted Successfully");
      } catch ({ message }) {
        clearInterval(intervalId.current);
        console.log({ message });
        setStateObject((prevState) => ({
          ...prevState,
          showTS: false,
          converting: false,
          uploading: false,
        }));
        errorToaster(message);
      }
    }, 1000);
  };

  const acceptedFileTypes = ["application/zip"];

  async function extractXmlFromZip(zipFile) {
    let jawUpper = 0;
    let jawLower = 0;
  
    try {
      const zip = new JSZip();
      const loadedZip = await zip.loadAsync(zipFile);
  
      // Function to check if the XML file exists in the specified folder or its nested folders
      const findXmlFile = (folder) => {
        const xmlFile = folder.file('ClearAlignerProductionResources.xml');
        return xmlFile || folder.folder(/./)?.file('ClearAlignerProductionResources.xml');
      };
  
      let xmlFile = findXmlFile(loadedZip); // Check if the XML file is directly in the root folder
  
      // If the XML file is not in the root folder, search for it inside nested folders
      if (!xmlFile) {
        const nestedFolders = Object.values(loadedZip.files)
          .filter((file) => file.dir) // Filter out only folders (directories)
          .map((folder) => folder.name);
  
        for (const folderName of nestedFolders) {
          const nestedFolder = loadedZip.folder(folderName);
          xmlFile = findXmlFile(nestedFolder);
          if (xmlFile) break; // If the XML file is found, stop searching
        }
      }
  
      if (xmlFile) {
        const xmlContent = await xmlFile.async('text');
        let x2js = new X2JS();
        const result = x2js.xml2js(xmlContent);
        result?.ClearAlignerProductionResources?.Molds?.Mold.forEach((step) => {
          if (step._Filename.includes('Default')) return;
          if (step._Type !== 'Aligner') return;
          step._Jaw === 'Upper' ? jawUpper += 1 : jawLower += 1;
        });
  
        console.log(jawUpper, jawLower);
  
        // The rest of the code remains the same
        if (jawUpper !== stateObject.treatmentSetups[0].upperSteps && jawLower !== stateObject.treatmentSetups[0].lowerSteps) {
          setStepsNotEqual(true);
          errorToaster(`
            Upper steps number: ${stateObject.treatmentSetups[0].upperSteps} &
            File upper steps: ${jawUpper} Lower steps number: ${stateObject.treatmentSetups[0].lowerSteps} &
            File Lower steps: ${jawLower}`);
        } else {
          setStepsNotEqual(false);
        }
      } else {
        errorToaster(`XML file not found in the ZIP archive`);
        console.log('XML file not found in the ZIP archive');
      }
    } catch (error) {
      console.error('Error extracting XML from ZIP:', error);
    }
  }
  
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="upload_container">
        <div>
          <div className="dropzone">
            <Dropzone
              accept={{ acceptedFileTypes }}
              onDrop={
                stateObject.caseId === "" ||
                Object.keys(stateObject.chosenTs).length === 0
                  ? null
                  : handleDrop
              }
              disabled={
                stateObject.caseId === "" ||
                stateObject.converting ||
                Object.keys(stateObject.chosenTs).length === 0
              }
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className={`h-100 w-100 d-inline-block`}
                >
                  <input {...getInputProps()} />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <img src="./Vector.svg" />
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        marginBottom: "0px",
                      }}
                    >
                      Drag and drop a file here, or click to{" "}
                      <span style={{ color: "#295DCD" }}>select a file</span>
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          <div style={{ display: "flex", gap: "16px", paddingTop: "8px" }}>
            <p style={{ marginRight: "16px" }}>Supported Format .ZIP</p>
            <p
              className="mb-0"
              style={{ color: stateObject.chosenFile > 0 ? "#00B189" : "black" }}
            >
              Selected file: {stateObject.chosenFile?.name}
            </p>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </div>
        </div>
        <div>
          <Button
            size="sm"
            className={`btn ${
              //stepsNotEqual ||
              stateObject.caseId === "" ||
              stateObject.converting ||
              Object.keys(stateObject.chosenTs).length === 0 ||
              Object.keys(stateObject.chosenFile).length === 0
                ? "select-btn-dis"
                : "active-btn"
            }`}
            onClick={() => {
              handleUpload();
            }}
            disabled={
              //stepsNotEqual ||
              stateObject.caseId === "" ||
              stateObject.converting ||
              Object.keys(stateObject.chosenTs).length === 0 ||
              Object.keys(stateObject.chosenFile).length === 0
            }
          >
            Convert
          </Button>
        </div>
      </div>
    </>
  );
};

export default Upload;
