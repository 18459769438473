import React, { useState } from "react";
import FetchCaseById from "./fetchCaseById";
import TSDetails from "./tsDetails";
import TSDropDown from "./tsDropDown";
import Upload from "./Upload";
import Loader from "./loader"
import ShowTreatmentPlan from "./showTreatmentPlan";
import ShowConfirmationModal from "./showConfirmationModal";

function TreatmentPlanInfo() {
    const [stateObject, setStateObject] = useState({
        caseId: '',
        uploading: false,
        converting: false,
        showError: false,
        errors: [],
        caseUuid: "",
        treatmentSetups: [],
        treatmentSetupsUuid: '',
        showTS: false, 
        chosenTs: {},
        doctor: {},
        patient: {},
        ownerType: false,
        isEonMFG: false,
        key: {},
        url: {},
        showConfirmation: false,
        updateTs: false,
        chosenFile: []
    })
  return <>
  <Loader stateObject={stateObject} />
  <div className="header">Converter</div>
  {stateObject.showTS &&
   <ShowTreatmentPlan stateObject={stateObject} setStateObject={setStateObject}/>}
   <ShowConfirmationModal stateObject={stateObject} setStateObject={setStateObject}/>
  <div className="main_container">
  <FetchCaseById stateObject={stateObject} setStateObject={setStateObject}/>
  <TSDropDown stateObject={stateObject} setStateObject={setStateObject}/>
  <TSDetails stateObject={stateObject}/>
  <Upload stateObject={stateObject} setStateObject={setStateObject}/>
  </div>
  </>;
}

export default TreatmentPlanInfo;
