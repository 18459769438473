import React, {useEffect} from "react";
import { TSMapper } from "./helpers/mappers";
import Select from "react-select";

function TSDropDown(props) {
  const { stateObject, setStateObject } = props;
  useEffect(() => {
      let ts = stateObject.treatmentSetups?.[0]
      if (ts) {
        if (stateObject.isEonMfg) {
          let TSData = TSMapper(ts);
          setStateObject((prevState) => ({ ...prevState, chosenTs: TSData }));
        } else {
          setStateObject((prevState) => ({ ...prevState, chosenTs: ts }));
        }
      }
  }, [stateObject.treatmentSetups])

  return (
    <div className="search-grid">
      <Select
        value={stateObject.treatmentSetups.filter((option) =>
          stateObject.isEonMfg
            ? option.title === stateObject.chosenTs.front_title
            : option.label === stateObject.chosenTs.label
        )}
        onChange={(ts) => {
          if (stateObject.isEonMfg) {
            let TSData = TSMapper(ts);
            setStateObject((prevState) => ({ ...prevState, chosenTs: TSData }));
          } else {
            setStateObject((prevState) => ({ ...prevState, chosenTs: ts }));
          }
        }}
        placeholder="Choose TS "
        options={stateObject.treatmentSetups}
        isDisabled={
          stateObject.treatmentSetups.length === 0 || stateObject.uploading
        }
      />
    </div>
  );
}

export default TSDropDown;
