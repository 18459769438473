import axios from "axios";
const mfgConfig = {
  headers: {
    "x-api-key": process.env.REACT_APP_TOKEN,
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8",
  },
};

const accessConfig = {
  headers: {
    Authorization: `Token ${process.env.REACT_APP_TOKEN}`,
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8",
  },
}

const axiosForMFG = axios.create({
  baseURL: process.env.REACT_APP_MFG_BASE_URL
})
const axiosForAccess = axios.create({
  baseURL: process.env.REACT_APP_ACCESS_BASE_URL
})
/*--------------------------------------------------------------------------------*/
/* Get case details by caseId for eon mfg                                   */
/*--------------------------------------------------------------------------------*/
export const getCaseDetailsByCaseIdApi = (caseId) => {
  return axiosForMFG.get(`/case/identifier/${caseId}`, mfgConfig)
  };

/*--------------------------------------------------------------------------------*/
/* Get Treatment setup By CaseUuid for eon mfg                                               
/*--------------------------------------------------------------------------------*/
export const getListPlansForCaseApi = (caseUuid, ownerType, params = {}) => {
  mfgConfig.params = params
  return axiosForMFG.get(`/case/${caseUuid}/ownerType/${ownerType}/plan`, mfgConfig)
  };

/*--------------------------------------------------------------------------------*/
/* Convert Treatment setup for eon mfg                                               
/*--------------------------------------------------------------------------------*/
  export const convertMFGTS = (ownerType, treatmentSetupsUuid, url) => {
    delete mfgConfig.params
    axiosForMFG.put(`/plan/${treatmentSetupsUuid}/ownerType/${ownerType}/convert`, {}, mfgConfig)
  }

  /*--------------------------------------------------------------------------------*/
/* Get Treatment setup By Case Id for access                                               */
/*--------------------------------------------------------------------------------*/
export const getTSByCaseIdApi = (caseId) => {
  return axiosForAccess.get(`/aligner_case_details/${caseId}`, accessConfig)
};

/*--------------------------------------------------------------------------------*/
/* Convert Treatment setup for eon Access                                               
/*--------------------------------------------------------------------------------*/
export const convertAccessTS = (caseId, treatmentSetupsUuid, url) => {
axiosForAccess.put(`/aligner_cases/${caseId}/treatment_setups/${treatmentSetupsUuid}`, {"new_converted_files_urls": [url], "new_converter": true}, accessConfig)
}